import React, { useState, useRef, useEffect } from 'react';
import { debounce } from 'lodash';
import { Box, Grid } from '@mui/material';
import { TextEditor } from '@/components';
import { DEFAULT_FORMAT_OPTIONS_WITH_MENTION } from '@/utils/constants';
import commonStyles from '@/utils/styles';
import { MentionItemType, UserType } from '@/utils/types';

const styles = {
	containerStyles: {
		...commonStyles.article,
		'& .ql-editor': {
			minHeight: 'calc(100vh - 300px)',
			padding: '45px 90px',
		},
		'& .ql-toolbar.ql-snow': {
			position: 'sticky',
			top: 0,
			zIndex: 1000,
			backgroundColor: '#f9f9fb',
			border: '1px solid #ccc !important',
			borderBottom: 'none !important',
		},
		'& .ql-toolbar::before': {
			content: '""',
			width: 'calc(100% + 1px)',
			height: '25px',
			backgroundColor: '#f9f9fb',
			position: 'absolute',
			borderLeft: '1px solid #f9f9fb',
			borderRight: '1px solid #f9f9fb',
			bottom: '40.5px',
			left: '-1px',
		},
		'& .ql-toolbar::after': {
			width: '100%',
			height: '5px',
			borderLeft: '1px solid #f9f9fb',
			borderRight: '3px solid #f9f9fb',
			position: 'absolute',
			top: '-1px',
			left: '-1px',
		},
		'& .ql-container.ql-snow': {
			borderColor: '#ccc !important;',
			borderTop: 'none !important;',
		},
		'&:not(:has(.ql-toolbar)) .ql-container.ql-snow': {
			borderColor: '#ccc !important;',
		},
		'& ol': {
			padding: 0,
		},
		'& ul': {
			padding: 0,
		},
		'& .ql-mention-list-container li': {
			lineHeight: '44px',
		},
	},
	emptyEditorContainer: {
		height: 'calc(100vh - 300px)',
		borderRadius: '8px',
		border: '1px solid #ccc',
		backgroundColor: '#fff',
	},
};

type EditorProps = {
	codox?: Record<string, any>;
	docId?: string;
	handleUpdateText?: (_key: string, _value: string) => void;
	readOnly?: boolean;
	text?: string;
	title?: string;
	type?: 'summary' | 'notes' | 'entityNotes';
	user?: UserType | null;
	usersForMentions?: MentionItemType[];
};

export const Editor = ({
	codox,
	docId,
	handleUpdateText,
	readOnly = false,
	text,
	type = 'summary',
	user,
	usersForMentions,
}: EditorProps) => {
	const [updatingMentionableUsers, setUpdatingMentionableUsers] = useState(false);

	const saveHandler = useRef(
		debounce((text) => {
			handleUpdateText!(type, text);
		}, 1000),
	);

	useEffect(() => {
		if (!usersForMentions) return;
		setUpdatingMentionableUsers(true);

		const timeout = setTimeout(() => {
			setUpdatingMentionableUsers(false);
		}, 1000);

		return () => clearTimeout(timeout);
	}, [usersForMentions]);

	const onChange = (editorText: string) => {
		saveHandler.current(editorText);
	};

	return (
		<Box sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
			<Grid
				container
				direction={'column'}
				height={'100%'}
				wrap="nowrap"
				sx={{ maxWidth: 1050, width: '100%' }}
				px={2}
			>
				<Grid item flexGrow={1}>
					{updatingMentionableUsers ? (
						<Box sx={styles.emptyEditorContainer} />
					) : (
						<TextEditor
							atValues={usersForMentions}
							dynamicToolbar={true}
							codox={codox}
							docId={`${docId}-${type}`}
							containerStyles={styles.containerStyles}
							formatOptions={DEFAULT_FORMAT_OPTIONS_WITH_MENTION}
							isCollaborative={true}
							onChange={onChange}
							value={text}
							user={user}
							readOnly={readOnly}
						/>
					)}
				</Grid>
			</Grid>
		</Box>
	);
};
