import { Theme } from '@mui/material';

const styles = {
	article: {
		wordBreak: 'break-word',
		'& p': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: '#49545B',
			fontSize: '16px',
			fontFamily: 'Inter',
		},
		'& blockquote': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: '#49545B',
			fontSize: '16px',
			fontFamily: 'Inter',
		},
		'& em': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: 'text.primary',
			fontSize: '16px',
			fontFamily: 'Inter',
		},
		'& a': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: (theme: Theme) => theme.palette.primary.main,
			fontSize: '16px',
			fontFamily: 'Inter',
		},
		'& h1': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '32px',
			color: 'text.primary',
			fontFamily: 'Poppins',
		},
		'& h2': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '32px',
			color: 'text.primary',
			fontFamily: 'Poppins',
		},
		'& h3': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '32px',
			color: 'text.primary',
			fontFamily: 'Poppins',
		},
		'& h4': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '24px',
			color: 'text.primary',
			fontFamily: 'Poppins',
		},
		'& h5': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '24px',
			color: 'text.primary',
			fontFamily: 'Poppins',
		},
		'& h6': {
			margin: '20px 0',
			wordBreak: 'break-word',
			lineHeight: '20px',
			color: 'text.primary',
			fontFamily: 'Poppins',
		},
		'& ol': {
			padding: '20px 40px',
		},
		'& ul': {
			padding: '20px 40px',
		},
		'& li': {
			lineHeight: '28px',
			color: 'text.primary',
			fontSize: '16px',
			fontFamily: 'Inter',
		},
		'& img.img-margin': {
			margin: '20px 0',
			maxWidth: '100%',
		},
		'& figcaption': {
			wordBreak: 'break-word',
			lineHeight: '28px',
			color: '#49545B',
			fontSize: '16px',
			fontFamily: 'Inter',
		},
	},
};

export default styles;
