import React from 'react';
import { Chip } from './Chip';
import Stack from '@mui/material/Stack';
import { Tooltip, Typography } from '@mui/material';

type ChipGroupType = {
	chips: string[];
	customStyle?: Record<string, any>;
	type?: 'simple' | 'table';
};

const styles = {
	typography: {
		fontSize: '14px',
		paddingX: '8px',
		textAlign: 'left',
		color: 'primary.main',
	},
	tooltipText: {
		fontSize: '14px',
		paddingX: '8px',
		textAlign: 'left',
	},
	ellipses: {
		width: '120px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		height: '35px',
		'& > .MuiChip-label': {
			display: '-webkit-box',
			WebkitLineClamp: '2',
			WebkitBoxOrient: 'vertical',
			whiteSpace: 'normal',
		},
	},
};

export const ChipGroup: React.FC<ChipGroupType> = ({ chips, customStyle = {}, type }) => {
	return (
		<Stack direction="column" spacing={1}>
			{chips.length < 4 ? (
				chips.map((chip, index) => (
					<Chip
						label={chip}
						key={`${chip}-${index}`}
						customStyle={{ ...(chip.length > 25 ? styles.ellipses : {}), ...customStyle }}
						type={type}
					/>
				))
			) : (
				<>
					{chips.slice(0, 3).map((chip, index) => (
						<Chip
							label={chip}
							key={`${chip}-${index}`}
							customStyle={{
								...(chip.length > 25 ? styles.ellipses : {}),
								...customStyle,
							}}
							type={type}
						/>
					))}
					<Tooltip
						title={chips.slice(3).map((chip, index) => (
							<Typography key={index} sx={styles.tooltipText}>
								{chip}
							</Typography>
						))}
					>
						<Typography sx={styles.typography}>{`+${chips.length - 3}`}</Typography>
					</Tooltip>
				</>
			)}
		</Stack>
	);
};
