import React, { ReactNode } from 'react';
import { Chip as MuiChip, Theme, Tooltip } from '@mui/material';

type ChipPropsType = {
	label?: string | ReactNode;
	size?: 'small' | 'medium';
	variant?: 'filled' | 'outlined';
	customStyle?: Record<string, any>;
	icon?: React.ReactElement;
	tooltipText?: string | React.ReactElement;
	handleClick?: (_event: React.SyntheticEvent<Element, Event>) => void;
	type?: 'simple' | 'table';
};

const styles = {
	chip: {
		backgroundColor: (theme: Theme) => theme.palette.primary.main,
		color: (theme: Theme) => theme.palette.common.white,
		height: '18px',
		'& .MuiChip-label': {
			padding: '2px 10px ',
			fontSize: '12px',
			lineHeight: '14px',
			textTransform: 'capitalize',
			fontWeight: 400,
		},
	},
};

export const Chip = ({
	label = '',
	size = 'small',
	variant = 'filled',
	customStyle = {},
	icon,
	tooltipText,
	handleClick,
	type = 'simple',
}: ChipPropsType) => {
	const tableChipStyle =
		type === 'table'
			? {
					height: '24px',
					'& .MuiChip-label': {
						fontSize: '16px',
					},
			  }
			: {};
	const customChip = () => (
		<MuiChip
			sx={[styles.chip, tableChipStyle, customStyle]}
			label={label}
			size={size}
			variant={variant}
			icon={icon}
			onClick={handleClick}
		/>
	);

	return tooltipText ? (
		<Tooltip title={tooltipText}>{customChip()}</Tooltip>
	) : (
		customChip()
	);
};
